import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Col, Form, iframe } from "react-bootstrap";
import Services from "../../services/Services";
import { MdOutlineCancel } from "react-icons/md";
import "./passwordpage.scss";

function PasswordPage() {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [token, setToken] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            nextTitle={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <div className="password">
            <h5>Şifre Yenileme</h5>
            <div className="input-container">
              <Form.Label
                style={{ textAlign: "left", display: "flex", fontWeight: 600 }}
                htmlFor="old-password"
              >
                Eski Şifre
              </Form.Label>
              <Form.Control
                type="text"
                style={{ height: 50 }}
                // placeholder="Eski Şifre"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />

              <div
                style={{
                  height: 1,
                  backgroundColor: "#d4d4d4",
                  marginBlock: 20,
                  marginTop: 30,
                }}
              />

              <Form.Label
                style={{ textAlign: "left", display: "flex", fontWeight: 600 }}
                htmlFor="new-password"
              >
                Yeni Şifre
              </Form.Label>
              <Form.Control
                type="text"
                style={{ height: 50 }}
                // placeholder="Yeni Şifre"
                value={newPassword}
                onChange={(e) => setnewPassword(e.target.value)}
              />
              <Form.Label
                style={{ textAlign: "left", display: "flex", fontWeight: 600 }}
                htmlFor="new-password-confirm"
              >
                Yeni Şifre Tekrar
              </Form.Label>
              <Form.Control
                type="text"
                style={{ height: 50 }}
                // placeholder="Yeni Şifre Tekrar"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />

              <div className="code-field">
                <Button
                  style={{ backgroundColor: "#DA3831", border: "none" }}
                  className="complete-button"
                  onClick={() => null}
                >
                  Kaydet
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default PasswordPage;
