import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Pagination, Spinner } from "react-bootstrap";
import "./allpostspage.scss";
import Services from "../../services/Services";
import moment from "moment";
import { StatusList } from "../../types/statusTypes";

function AllPostsPage() {
  const width = window.innerWidth;
  const [allDatas, setAllDatas] = useState([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);

  // for pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 20;
  const totalPages = Math.ceil(allDatas.length / itemsPerPage);
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const displayedData = allDatas.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("DD.MM.YYYY HH:mm");
  };

  const getStatusLabel = (statusValue) => {
    const status = StatusList.find((item) => item.id === +statusValue);
    return status ? status.label : "";
  };

  const fetchDatas = () => {
    setLoading(true);
    const storedToken = localStorage.getItem("token");
    setToken(storedToken);
    Services.GetPosts(
      storedToken,
      StatusList.map((x) => x.id)
    )
      .then((res) => {
        if (res.data.status) {
          setAllDatas(res.data.result);
        } else {
          setAllDatas([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setAllDatas([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            // nextTitle={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <h5 className="text-center">Tüm Gönderiler</h5>

          {loading && (
            <div
              style={{
                display: "flex",
                height: "50vh",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 30,
              }}
            >
              <Spinner
                animation="border"
                role="status"
                style={{
                  borderBottomColor: "#da3831",
                  borderRightColor: "#da3831",
                  borderLeftColor: "#da3831",
                  borderTopColor: "white",
                }}
              />
            </div>
          )}

          {/* Data Table */}
          {!loading && allDatas?.length === 0 && (
            <p className="text-center">Gönderi bulunamamıştır.</p>
          )}

          {/* Table */}
          {!loading && allDatas?.length > 0 && (
            <>
              <table style={{ marginBottom: 20 }} className="table">
                <thead>
                  <tr>
                    <th style={{ width: 20, textAlign: "center" }}>No</th>
                    <th style={{ textAlign: "center", width: 200 }}>Başlık</th>
                    <th style={{ textAlign: "center", width: 150 }}>Tarih</th>
                    <th style={{ textAlign: "center", width: 150 }}>Durum</th>
                    <th style={{ textAlign: "center", width: 50 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {displayedData?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          width: 20,
                          textAlign: "center",
                          fontWeight: "600",
                        }}
                      >
                        {(page - 1) * itemsPerPage + index + 1}
                      </td>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {item.title}
                      </td>
                      <td style={{ textAlign: "center", width: 150 }}>
                        {moment
                          .unix(item.createdDateTime)
                          .format("DD.MM.YYYY HH:mm")}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          width: 150,
                          color:
                            item.status === "1"
                              ? "green"
                              : item.status === "2" ||
                                item.status === "16" ||
                                item.status === "21"
                              ? "red"
                              : "orange",
                        }}
                      >
                        {getStatusLabel(item.status)}
                      </td>
                      <td
                        onClick={() => {
                          window.open(
                            `/post-details/${item.barcode}`,
                            "_blank"
                          );
                        }}
                        style={{
                          textAlign: "center",
                          width: 150,
                          color: "#da3831",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        Detay
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Pagination */}
              <Pagination
                className="pagination-container"
                style={{ float: "right", marginBottom: 100 }}
              >
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === page}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </>
          )}
        </div>
      </Layout>
    </div>
  );
}

export default AllPostsPage;
