import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Col, Form, iframe } from "react-bootstrap";
import Services from "../../services/Services";
import { MdOutlineCancel } from "react-icons/md";
import "./profilepage.scss";

function ProfilePage() {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [token, setToken] = useState("");
  const [user, setUser] = useState({
    name: ".....",
    lastname: ".....",
    phone: "........",
  });

  const fetchProfile = () => {
    const storedToken = localStorage.getItem("token");
    setToken(storedToken);
    console.log(storedToken);
    Services.GetProfile(storedToken)
      .then((res) => {
        if (res.data.status) {
          setUser({
            name: res.data.result.name,
            lastname: res.data.result.lastname,
            phone: res.data.result.phone,
          });
          //   setLoading(false);
        } else {
          console.log("hata");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            nextTitle={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <div className="profile">
            <h5>Profil Bilgileri</h5>
            <div className="input-container">
              <Form.Label
                style={{ textAlign: "left", display: "flex", fontWeight: 600 }}
                htmlFor="ad"
              >
                Ad
              </Form.Label>
              <Form.Control
                type="text"
                style={{ height: 50 }}
                placeholder="Ad"
                value={user.name}
                onChange={(e) => setUser({ ...user, name: e.target.value })}
              />
              <Form.Label
                style={{ textAlign: "left", display: "flex", fontWeight: 600 }}
                htmlFor="soyad"
              >
                Soyad
              </Form.Label>
              <Form.Control
                type="text"
                style={{ height: 50 }}
                placeholder="Soyad"
                value={user.lastname}
                onChange={(e) => setUser({ ...user, lastname: e.target.value })}
              />
              <Form.Label
                style={{ textAlign: "left", display: "flex", fontWeight: 600 }}
                htmlFor="telefon"
              >
                Telefon
              </Form.Label>
              <Form.Control
                type="text"
                style={{ height: 50 }}
                placeholder="Telefon"
                value={user.phone}
                onChange={(e) => setUser({ ...user, phone: e.target.value })}
              />

              <div className="code-field">
                <Button
                  style={{ backgroundColor: "#DA3831", border: "none" }}
                  className="complete-button"
                  onClick={() => null}
                >
                  Kaydet
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default ProfilePage;
