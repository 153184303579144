import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Col, Form, iframe } from "react-bootstrap";
import Services from "../../services/Services";
import { MdOutlineCancel } from "react-icons/md";
import "./deliverypage.scss";

function DeliveryPage() {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [showAlert, setShowAlert] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [resendedCode, setResendedCode] = useState(false);
  // datas
  const [token, setToken] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [filteredList, setFilteredList] = useState();
  // fields
  const [search, setSearch] = useState("");
  const [code, setCode] = useState("");

  function handleClose() {
    setShowAlert(false);
  }

  const addDeliveredOrders = () => {
    if (filteredList) {
      if (code?.length > 0) {
        let otpType = "EasypointOTP";
        otpType = resendedCode
          ? "EasypointOTP"
          : filteredList?.deliveryPasswordType === "hashBased"
          ? "TrendyolOTP"
          : "EasypointOTP";
        console.log(filteredList?.id);
        Services.CheckCode(token, filteredList.id, code)
          .then((res) => {
            console.log("code confirmation response: ", res.data);
            if (res.data.status) {
              setButtonLoading(true);
              Services.PostComplete(token, filteredList?.barcode, otpType, code)
                .then((response) => {
                  console.log("teslim response:", response.data);
                  if (response.data.status) {
                    window.alert("Gönderi teslim edilmiştir.");
                    setFilteredList(null);
                    setSearch("");
                    setCode("");
                    setButtonLoading(false);
                    setResendedCode(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  window.alert("Gönderi teslim edilirken hata oluştu.");
                  setButtonLoading(false);
                });
            } else {
              window.alert("Girilen kod değeri hatalıdır.");
            }
          })
          .catch((err) => {
            console.log(err);
            window.alert("Girilen kod değeri hatalıdır.");
          });
      } else {
        window.alert("Lütfen kodu giriniz.");
      }
    } else {
      window.alert("Seçili bir gönderi yokken teslim işlemi yapılamaz.");
    }
  };

  // barkod: 62151895124297

  const resendCode = () => {
    if (filteredList) {
      setSendCodeLoading(true);
      Services.ResendCode(token, filteredList.id)
        .then((response) => {
          if (response.data.status) {
            setResendedCode(true);
            window.alert(
              "Gönderiye tanımlı telefon numarasına tekrardan kod gönderilmiştir."
            );
            setSendCodeLoading(false);
          } else {
            setSendCodeLoading(false);
            window.alert(
              "Gönderiye tanımlı telefon numarasına tekrardan kod gönderilirken bir hata oluştu."
            );
          }
        })
        .catch((error) => {
          setSendCodeLoading(false);
          window.alert(
            "Gönderiye tanımlı telefon numarasına tekrardan kod gönderilirken bir hata oluştu."
          );
          console.log(error);
        });
    } else {
      window.alert(
        "Seçili bir gönderi olmadan tekrar kod gönderme işlemi yapılamaz."
      );
    }
  };

  function applySortFilter(search) {
    if (search) {
      setAddLoading(true);
      const storedToken = localStorage.getItem("token");
      setToken(storedToken);
      Services.GetPostByBarcode(storedToken, [search], [5, 9])
        .then((response) => {
          if (response.data.status) {
            const foundItem = response.data.result[0];
            setFilteredList(foundItem);
            setSearch(null);
          } else {
            window.alert("Gönderi bulunamadı, barkodunuzu kontrol ediniz.");
            setSearch(null);
          }
        })
        .catch((error) => {
          window.alert("Gönderi bulunamadı, barkodunuzu kontrol ediniz.");
          console.log(error);
          setSearch(null);
        })
        .finally(() => setAddLoading(false));
    }
  }

  function removeSelectedElement() {
    setFilteredList(null);
  }

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            nextTitle={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <div className="delivery">
            <h5>Teslim Et</h5>
            <div className="input-container">
              <Form.Control
                type="text"
                style={{ height: 50 }}
                placeholder="Barkod"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                disabled={addLoading}
                className="search"
                onClick={() => applySortFilter(search)}
              >
                {addLoading ? "Aranıyor..." : "Bul"}
              </Button>
              {filteredList && (
                <div className="card">
                  <div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                      }}
                    >
                      <p className="title">
                        {filteredList?.takenName[0]}***{" "}
                        {filteredList?.takenName.split(" ")[1][0]}***
                      </p>
                      <p className="api-type">({filteredList?.apiUserType})</p>
                    </div>{" "}
                    <p className="sub-title">{filteredList?.barcode}</p>
                  </div>

                  <div
                    onClick={removeSelectedElement}
                    className="cancel-button"
                  >
                    <MdOutlineCancel size={24} />
                  </div>
                </div>
              )}
              {filteredList ? (
                <div className="code-field">
                  <h6 className="mb-3">Kod Giriniz</h6>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    style={{ height: 50 }}
                    placeholder="Kod"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <p
                    onClick={() => setShowInfo(true)}
                    style={{ color: "#636363", cursor: "pointer" }}
                  >
                    <ins>Şifre Nasıl Bulunur?</ins>
                  </p>
                  <Button
                    style={{
                      backgroundColor: "white",
                      borderColor: "gray",
                      color: "gray",
                    }}
                    className="resend-button"
                    onClick={() => resendCode()}
                  >
                    {sendCodeLoading
                      ? "Kod Gönderiliyor..."
                      : "Tekrar Kod Gönder"}
                  </Button>
                  <Button
                    disabled={buttonLoading}
                    style={{ backgroundColor: "#DA3831", border: "none" }}
                    className="complete-button"
                    onClick={() => addDeliveredOrders()}
                  >
                    {buttonLoading
                      ? "Teslim Tamamlanıyor..."
                      : "Teslimi Tamamla"}
                  </Button>
                </div>
              ) : (
                <div style={{ marginBottom: 300 }} />
              )}
            </div>
          </div>
        </div>

        <Modal show={showAlert} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Uyarı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {search !== ""
              ? "Gönderi bulunamadı, barkodunuzu kontrol ediniz."
              : "Lütfen barkod giriniz."}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showInfo} onHide={() => setShowInfo(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Şifre Nasıl Bulunur</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src="https://easypointapi.com/files/esnaf-kodsoru-202310031532.html"
              title="External Content"
              width="100%"
              height="150px"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowInfo(false)}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
}

export default DeliveryPage;
