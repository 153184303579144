import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import "./homepage.scss";
import Services from "../../services/Services";
import { StatusList } from "../../types/statusTypes";

function HomePage() {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [barcode, setBarcode] = useState("");
  const [postList, setPostList] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const getStatusLabel = (statusValue) => {
    const status = StatusList.find((item) => item.id === +statusValue);
    return status ? status.label : "";
  };

  function getPost() {
    if (postList.some((item) => item.barcode === barcode)) {
      return;
    }

    setLoading(true);

    Services.GetPostByOnlyBarcode(token, barcode)
      .then((res) => {
        setPostList([...postList, res.data.result[0]]);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setBarcode("");
      });
  }

  function removeItem(index) {
    const newList = [...postList];
    newList.splice(index, 1);
    setPostList(newList);
  }

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"Anasayfa"}
            back={"reset"}
            action={(e) => null}
          />
        )}

        <div className="search-container">
          <input
            type="text"
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            placeholder="Barkod giriniz"
            onKeyDown={(e) => {
              if (e.key === "Enter") getPost();
            }}
          />
          <button onClick={getPost} disabled={loading}>
            {loading ? <div className="spinner" /> : "Ara"}
          </button>
        </div>

        <div className="result-container">
          {postList.length > 0 && (
            <div>
              <h5 style={{ color: "gray" }} className="text-center">
                Sonuçlar
              </h5>

              {postList.map((item, index) => (
                <div className="d-flex ">
                  <p key={index}>
                    {`${index + 1} - ${item.id}: `}
                    <div>
                      <strong
                        onClick={() => {
                          window.open(
                            `/post-details/${item.barcode}`,
                            "_blank"
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {item.apiUserType} - {item.willTakenBy} - {item.barcode}{" "}
                        - ({getStatusLabel(item.status)})
                      </strong>
                    </div>
                  </p>
                  <p
                    style={{
                      color: "red",
                      fontWeight: 600,
                      fontSize: 18,
                      cursor: "pointer",
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                    onClick={() => removeItem(index)}
                  >
                    X
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="menu-container">
          <h5 style={{ color: "gray" }} className="text-center">
            İşlemler
          </h5>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 50,
            }}
          >
            <div
              style={{
                marginRight: 40,
                justifyContent: "center",
              }}
              onClick={() => navigate("/receiving")}
              className="box"
            >
              Teslim Al
            </div>

            <div onClick={() => navigate("/delivery")} className="box">
              Teslim Et
            </div>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 50,
            }}
          >
            <div
              style={{ marginRight: 40 }}
              onClick={() => navigate("/giving-back")}
              className="box"
            >
              İade Et
            </div>
            <div
              style={{}}
              onClick={() => navigate("/cloakroom")}
              className="box"
            >
              Vestiyer İşlemleri
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 50,
            }}
          >
            <div
              style={{ marginRight: 40 }}
              onClick={() => navigate("/handsfree")}
              className="box"
            >
              Handsfree
            </div>
            <div
              style={{}}
              onClick={() => navigate("/all-posts")}
              className="box"
            >
              Tüm Gönderiler
            </div>
          </div>
          <div
            style={{ height: 3, backgroundColor: "white", marginBottom: 50 }}
          />
          <h5 style={{ color: "gray" }} className="text-center">
            Profil İşlemleri
          </h5>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 50,
            }}
          >
            <div
              style={{ marginRight: 40 }}
              onClick={() => navigate("/profile")}
              className="box"
            >
              Profil Bilgilerim
            </div>
            <div onClick={() => navigate("/password")} className="box">
              Şifre Yenileme
            </div>
          </div>
          <div
            style={{ height: 3, backgroundColor: "white", marginBottom: 50 }}
          />
          <h5 style={{ color: "gray" }} className="text-center">
            Geçmiş İşlemler
          </h5>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 50,
            }}
          >
            <div onClick={() => navigate("/past-posts")} className="box">
              Geçmiş Gönderiler
            </div>
          </div>

          <div
            style={{ height: 3, backgroundColor: "white", marginBottom: 50 }}
          />
          <h5 style={{ color: "gray" }} className="text-center">
            Diğer İşlemler
          </h5>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 50,
            }}
          >
            <div
              style={{ marginRight: 40 }}
              onClick={() => navigate("/technical-support")}
              className="box"
            >
              Teknik Destek
            </div>
            <div onClick={() => navigate("/find-package")} className="box">
              İsimden Paket Bul
            </div>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 50,
            }}
          >
            <div onClick={() => navigate("/malls")} className="box">
              AVM Listesi
            </div>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{ color: "red" }}
              onClick={() => navigate("/")}
              className="box"
            >
              Çıkış Yap
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default HomePage;
