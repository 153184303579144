import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Form } from "react-bootstrap";
import Services from "../../services/Services";
import { MdOutlineContentCopy } from "react-icons/md";

import "./findpackagepage.scss";

function FindPackagePage() {
  // const navigate = useNavigate();
  const width = window.innerWidth;
  const [showAlert, setShowAlert] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  // datas
  const [orderList, setOrderList] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [name, setName] = useState("");

  function findItemsWithName(list, name) {
    const filteredList = list.filter((_user) =>
      _user.takenName.toLowerCase().includes(name.toLowerCase())
    );
    return filteredList;
  }

  function handleClose() {
    setShowAlert(false);
  }

  const handleCopy = async (barcode) => {
    try {
      await navigator.clipboard.writeText(barcode);
      alert(`${barcode} no'lu barkod kopyalanmıştır.`);
    } catch (error) {
      console.log(error);
    }
  };

  function applySortFilter(name) {
    if (name) {
      const filteredList = findItemsWithName(orderList, name);
      if (filteredList?.length > 0) {
        setFilteredOrders(filteredList);
      } else {
        window.alert("Bulunamadı");
        setFilteredOrders([]);
      }
    } else {
      window.alert("Ad Soyad kısmını giriniz");
    }
  }

  const fetchDatas = () => {
    const storedToken = localStorage.getItem("token");
    Services.GetPosts(storedToken, [3, 5, 9])
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          setOrderList(res.data.result);
        } else {
          setOrderList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setOrderList([]);
      });
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            nextTitle={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <div className="find">
            <h5>İsimden Paket Bul</h5>
            <div className="input-container">
              <Form.Control
                type="text"
                style={{ height: 50 }}
                placeholder="Ad Soyad"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Button className="search" onClick={() => applySortFilter(name)}>
                Bul
              </Button>
              {filteredOrders?.map((order, index) => (
                <div
                  className="card"
                  key={index}
                  onClick={() => handleCopy(order.barcode)}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <p className="title">
                          {order?.takenName[0]}***{" "}
                          {order?.takenName.split(" ")[1][0]}***
                        </p>
                        <p className="api-type">({order?.apiUserType})</p>
                      </div>{" "}
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <p className="sub-title">{order?.barcode}</p>{" "}
                        <p
                          style={{
                            marginTop: -15,
                            marginLeft: 10,
                            color: "#DA3831",
                            fontSize: 12,
                            alignSelf: "center",
                          }}
                        >
                          {order?.status === "3" && "(Bekleniyor)"}
                          {order?.status === "5" && "(Zimmete Alındı)"}
                          {order?.status === "9" && "(İade Edilecek)"}
                        </p>
                      </div>
                    </div>
                    <MdOutlineContentCopy
                      style={{
                        fontSize: 18,
                        color: "gray",
                        position: "absolute",
                        right: 15,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Modal show={showAlert} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Uyarı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {name !== ""
              ? "Gönderi bulunamadı, barkodunuzu kontrol ediniz."
              : "Lütfen barkod giriniz."}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showInfo} onHide={() => setShowInfo(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Şifre Nasıl Bulunur</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src="https://easypointapi.com/files/esnaf-kodsoru-202310031532.html"
              title="External Content"
              width="100%"
              height="150px"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowInfo(false)}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
}

export default FindPackagePage;
