import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/homepage/HomePage";
import DeliveryPage from "./pages/deliverypage/DeliveryPage";
import LoginPage from "./pages/loginpage/LoginPage";
import ReceivingPage from "./pages/receivingpage/ReceivingPage";
import PastPostsPage from "./pages/pastpostspage/PastPostsPage";
import ProfilePage from "./pages/profilepage/ProfilePage";
import GivingBackPage from "./pages/givingbackpage/GivingBackPage";
import PasswordPage from "./pages/passwordpage/PasswordPage";
import TechnicalSupportPage from "./pages/technicalsupportpage/TechnicalSupportPage";
import FindPackagePage from "./pages/findpackagepage/FindPackagePage";
import CloakroomPage from "./pages/cloakroompage/CloakroomPage";
import HandsfreePage from "./pages/handsfree/HandsfreePage";
import DetailPage from "./pages/detailpage/DetailPage";
import AllPostsPage from "./pages/allpostspage/AllPostsPage";
import MallsPage from "./pages/mallspage/MallsPage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        {/* <Route path="/home" element={<HomePage />} />
        <Route path="/post-details/:id" element={<DetailPage />} />
        <Route path="/delivery" element={<DeliveryPage />} />
        <Route path="/receiving" element={<ReceivingPage />} /> */}
        <Route path="/handsfree" element={<HandsfreePage />} />
        {/* <Route path="/past-posts" element={<PastPostsPage />} />
        <Route path="/all-posts" element={<AllPostsPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/giving-back" element={<GivingBackPage />} />
        <Route path="/cloakroom" element={<CloakroomPage />} />
        <Route path="/password" element={<PasswordPage />} />
        <Route path="/technical-support" element={<TechnicalSupportPage />} />
        <Route path="/find-package" element={<FindPackagePage />} />
        <Route path="/malls" element={<MallsPage />} /> */}
      </Routes>{" "}
    </>
  );
}

export default App;
