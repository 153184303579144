// LoginPage.js

import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Alert,
  Spinner,
} from "react-bootstrap";
import "./loginpage.scss";
import logo from "../../assets/images/logo.png";
import Services from "../../services/Services";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.target);
    const username = formData.get("username");
    const password = formData.get("password");

    console.log("username: ", username);
    console.log("password: ", password);
    Services.GetToken()
      .then((response) => {
        console.log("get token: ", response.data);
        if (response.data.status) {
          Services.Login(response.data.result.token, username, password)
            .then((res) => {
              console.log("login: ", res.data);
              if (res.data.status) {
                const token = res.data.result.token;
                localStorage.setItem("token", token);
                console.log(token);
                navigate("/handsfree");
              } else {
                setShowAlert(true);
              }
            })
            .catch(() => {
              setShowAlert(true);
            })
            .finally(() => setLoading(false));
        } else {
          setShowError(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setShowError(true);
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="login">
      <Container className="login-container">
        <Row className="justify-content-md-center align-items-center vh-100">
          <Col xs={12} sm={8} md={6} lg={4}>
            <div className="text-center mb-4">
              {/* <img
                // style={{ marginBottom: "3.5rem" }}
                src={logo}
                alt="Logo"
                width="200"
              /> */}
              <h3 className="h3 mb-3 font-weight-bold">Handsfree Panel</h3>
            </div>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="username">
                <Form.Label style={{ fontWeight: "600", color: "white" }}>
                  Kullanıcı Adı
                </Form.Label>
                <Form.Control
                  type="text"
                  style={{ height: 50 }}
                  placeholder="Kullanıcı Adı"
                  name="username" // Add the name attribute
                />
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label style={{ fontWeight: "600", color: "white" }}>
                  Parola
                </Form.Label>
                <Form.Control
                  type="password"
                  style={{ height: 50 }}
                  placeholder="Parola"
                  name="password" // Add the name attribute
                />
              </Form.Group>

              <Button
                style={{
                  fontWeight: "600",
                  marginTop: 50,
                  height: 50,
                  backgroundColor: "white",
                  color: "black",
                }}
                className="btn-login"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Giriş Yap"
                )}
              </Button>
            </Form>

            <Alert
              variant="danger"
              show={showAlert}
              onClose={() => setShowAlert(false)}
              dismissible
              style={{ marginTop: 20 }}
            >
              Giriş bilgileriniz hatalıdır, lütfen tekrar deneyiniz.
            </Alert>
            <Alert
              variant="danger"
              show={showError}
              onClose={() => setShowError(false)}
              dismissible
              style={{ marginTop: 20 }}
            >
              Bir hata oluştu.
            </Alert>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
