import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Container, Col, Row, Modal, Button, Form } from "react-bootstrap";
import "./detailpage.scss";
import Services from "../../services/Services";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StatusList } from "../../types/statusTypes";

function DetailPage() {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [token, setToken] = useState("");
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [processResult, setProcessResult] = useState("");
  const [otp, setOtp] = useState("");
  const { id } = useParams();

  const handleProcess = () => {
    if (data.status === "3") {
      Services.SetInBranch(token, data.id)
        .then((res) => {
          console.log("response set-in-branch: ", res.data);
          if (res.data.status) {
            setProcessResult("success");
            setShowResultModal(true);
            // success case
          } else {
            setProcessResult("fail");
            setShowResultModal(true);
            // fail case
          }
        })
        .catch((err) => {
          console.log(err);
          setProcessResult("fail");
          setShowResultModal(true);
          // fail case
        })
        .finally(() => setShowModal(false));
    } else if (data.status === "5") {
      const otpType =
        data.deliveryPasswordType === "hashBased"
          ? "TrendyolOTP"
          : "EasypointOTP";
      console.log(data.barcode, otpType, otp);
      Services.PostComplete(token, data.barcode, otpType, otp)
        .then((res) => {
          console.log("response deliver: ", res.data);
          if (res.data.status) {
            setProcessResult("success");
            setShowResultModal(true);
            // success case
          } else {
            setProcessResult("fail");
            setShowResultModal(true);
            // fail case
          }
        })
        .catch((err) => {
          console.log(err);
          setProcessResult("fail");
          setShowResultModal(true);
          // fail case
        })
        .finally(() => setShowModal(false));
    } else if (data.status === "9") {
      Services.PostReturn(token, data.barcode)
        .then((res) => {
          console.log("response return: ", res.data);
          if (res.data.status) {
            setProcessResult("success");
            setShowResultModal(true);
            // success case
          } else {
            setProcessResult("fail");
            setShowResultModal(true);
            // fail case
          }
        })
        .catch((err) => {
          console.log(err);
          setProcessResult("fail");
          setShowResultModal(true);
          // fail case
        })
        .finally(() => setShowModal(false));
    }
  };

  const getStatusLabel = (statusValue) => {
    const status = StatusList.find((item) => item.id === +statusValue);
    return status ? status.label : "";
  };

  const formatDate = (date) => {
    const formattedDate = moment.unix(date).format("DD.MM.YYYY - HH:mm");
    return formattedDate;
  };

  useEffect(() => {
    const _token = localStorage.getItem("token");
    setToken(_token);
    Services.GetPostByOnlyBarcode(_token, id)
      .then((res) => {
        console.log(res.data);
        setData(res.data.result[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            // nextTitle={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        {data === null ? (
          <div style={{ height: 1000, width: width }}>
            <p style={{ textAlign: "center", fontWeight: 600 }}>
              Bekleniyor...
            </p>
          </div>
        ) : (
          <div className="detail">
            <Container style={{ marginBottom: 30 }}>
              <Row>
                {/* sm 7 olacak */}
                <Col xs={12} sm={12}>
                  <div className="paper">
                    <h4 style={{ textAlign: "center", color: "#0052bd" }}>
                      {data.title}
                    </h4>
                    <div
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></div>
                    <Col className="d-flex justify-content-between">
                      <text style={{ fontSize: 16, fontWeight: "700" }}>
                        ID:
                      </text>
                      <text>{data.id}</text>
                    </Col>
                    <div className="divider" />
                    <Col className="d-flex justify-content-between">
                      <text style={{ fontSize: 16, fontWeight: "700" }}>
                        Barkod:
                      </text>
                      <text>{data.barcode}</text>
                    </Col>
                    <div className="divider" />
                    {/* <Col className="d-flex justify-content-between">
                      <text style={{ fontSize: 16, fontWeight: "700" }}>
                        Alıcı Kurumsal Üye:
                      </text>
                      <text>{data.corporationTitle}</text>
                    </Col>
                    <div className="divider" /> */}
                    <Col className="d-flex justify-content-between">
                      <text style={{ fontSize: 16, fontWeight: "700" }}>
                        Kargo Türü:
                      </text>
                      <text>Paket</text>
                    </Col>
                    <div className="divider" />
                    {data.takenName && (
                      <>
                        <Col className="d-flex justify-content-between">
                          <text style={{ fontSize: 16, fontWeight: "700" }}>
                            Gönderen:
                          </text>
                          <text>{data.takenName}</text>
                        </Col>
                        <div className="divider" />
                      </>
                    )}
                    {data.willTakenBy !== " " && (
                      <>
                        {" "}
                        <Col className="d-flex justify-content-between">
                          <text style={{ fontSize: 16, fontWeight: "700" }}>
                            Alıcı:
                          </text>
                          <text>{data.willTakenBy}</text>
                        </Col>
                        <div className="divider" />
                      </>
                    )}
                    {data.phone !== false && (
                      <>
                        {" "}
                        <Col className="d-flex justify-content-between">
                          <text style={{ fontSize: 16, fontWeight: "700" }}>
                            Alıcı Telefon No:
                          </text>
                          <text>{data.phone.slice(0, -7) + "*******"}</text>
                        </Col>
                        <div className="divider" />
                      </>
                    )}
                    <Col className="d-flex justify-content-between">
                      <text style={{ fontSize: 16, fontWeight: "700" }}>
                        Teslim Edilecek İl:
                      </text>
                      <text>{data.sentToCity}</text>
                    </Col>
                    <div className="divider" />
                    <Col className="d-flex justify-content-between">
                      <text style={{ fontSize: 16, fontWeight: "700" }}>
                        Teslim Edilecek İlçe:
                      </text>
                      <text>{data.sentToRegion}</text>
                    </Col>
                    <div className="divider" />
                    <Col className="d-flex justify-content-between">
                      <text style={{ fontSize: 16, fontWeight: "700" }}>
                        Teslim Edilecek Easy Point Noktası:
                      </text>
                      <text>{data.pointTitle}</text>
                    </Col>{" "}
                    <div className="divider" />
                    {data.shipmentFirm && (
                      <>
                        <Col className="d-flex justify-content-between">
                          <text style={{ fontSize: 16, fontWeight: "700" }}>
                            Kargo Firması:
                          </text>
                          <text>{data.shipmentFirm}</text>
                        </Col>
                        <div className="divider" />
                      </>
                    )}
                    {data.shipmentNo && (
                      <>
                        {" "}
                        <Col className="d-flex justify-content-between">
                          <text style={{ fontSize: 16, fontWeight: "700" }}>
                            Kargo Takip No:
                          </text>
                          <text>{data.shipmentNo}</text>
                        </Col>
                        <div className="divider" />
                      </>
                    )}
                    <Col className="d-flex justify-content-between">
                      <text style={{ fontSize: 16, fontWeight: "700" }}>
                        Gönderi Tarihi:
                      </text>
                      <text>{formatDate(data.createdDateTime)}</text>
                    </Col>
                    <div className="divider" />
                    {data.status === "1" && (
                      <>
                        <Col className="d-flex justify-content-between">
                          <text style={{ fontSize: 16, fontWeight: "700" }}>
                            Teslim Tarihi:
                          </text>
                          <text>{formatDate(data.completedDateTime)}</text>
                        </Col>
                        <div className="divider" />
                      </>
                    )}
                    <Col className="d-flex justify-content-between">
                      <text style={{ fontSize: 16, fontWeight: "700" }}>
                        Ödeme Durumu:
                      </text>
                      <text
                        style={{
                          backgroundColor:
                            data.payment === false
                              ? "#FF7373"
                              : "rgb(77 177 88)",
                          color: "white",
                          padding: 3,
                          borderRadius: 5,
                          paddingInline: 10,
                        }}
                      >
                        {data.payment == false ? "Ödeme Bekleniyor" : "Ödendi"}
                      </text>
                    </Col>
                    <div className="divider" />
                    <Col className="d-flex justify-content-between">
                      <text style={{ fontSize: 16, fontWeight: "700" }}>
                        Gönderi Durumu:
                      </text>

                      <text
                        style={{
                          backgroundColor: "rgb(77 177 88)",
                          color: "white",
                          padding: 3,
                          borderRadius: 5,
                          paddingInline: 10,
                        }}
                      >
                        {getStatusLabel(data.status)}
                      </text>
                    </Col>
                    {(data.status === "3" ||
                      data.status === "5" ||
                      data.status === "9") && (
                      <>
                        <div className="divider" />
                        <Col className="d-flex justify-content-between">
                          <text style={{ fontSize: 16, fontWeight: "700" }}>
                            Teslim
                          </text>

                          {(data.status === "3" &&
                            data.apiUserType === "handsfree") === false && (
                            <Button
                              style={{
                                backgroundColor: "#DA3831",
                                border: "none",
                                fontWeight: 600,
                              }}
                              onClick={() => setShowModal(true)}
                            >
                              {data.status === "3" && "Teslim Al"}
                              {data.status === "5" && "Teslim Et"}
                              {data.status === "9" && "İade Et"}
                            </Button>
                          )}
                        </Col>
                      </>
                    )}
                  </div>
                </Col>
                {/* <Col xs={8} sm={5}>
                  <div className="paper">
                    <h5 style={{ textAlign: "center", color: "#0052bd" }}>
                      Gönderi Logları
                    </h5>
                    {data.logs?.length >= 1 ? (
                      <>
                        {data.logs.map((log, i) => (
                          <>
                            <Row className="justify-content-center">
                              <div className="d-flex justify-content-end mb-2">
                                {formatDate(log.createdDateTime.toString())}
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#FFEFD1",
                                  padding: 10,
                                  width: "95%",
                                  textAlign: "center",
                                  borderRadius: 5,
                                  marginBottom: 10,
                                }}
                              >
                                Gönderinin [5464654656546] adlı taşıma görevlisi
                                tarafından teslim alındığı bilgisi Getir’e
                                bildirilirken bir sorun oluştu.
                              </div>
                            </Row>
                            <div className="divider" />
                          </>
                        ))}
                      </>
                    ) : (
                      <p>Log bulunmamaktadır.</p>
                    )}
                  </div>
                </Col> */}
              </Row>
            </Container>
            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {data.status === "3" && "Teslim Al"}

                  {data.status === "5" && "Teslim Et"}

                  {data.status === "9" && "İade Et"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    marginBlock: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <text>
                    {data.status === "3" &&
                      "Teslim almak istediğinize emin misiniz?"}

                    {data.status === "5" &&
                      "Teslim etmek için otp kodunu giriniz."}

                    {data.status === "9" &&
                      "İade etmek istediğinize emin misiniz?"}
                  </text>

                  {data.status === "5" ? (
                    <div
                      style={{
                        width: "100%",
                        marginTop: 20,
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Form.Control
                        className="mb-2"
                        type="text"
                        style={{ height: 50, width: 300 }}
                        placeholder="Kod"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <button
                        onClick={handleProcess}
                        style={{
                          paddingBlock: 5,
                          paddingInline: 20,
                          borderRadius: 5,
                          border: "none",
                          backgroundColor: "green",
                          color: "white",
                          fontWeight: "600",
                          marginTop: 20,
                        }}
                      >
                        Teslim Et
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex ">
                      {" "}
                      <button
                        onClick={handleProcess}
                        style={{
                          paddingBlock: 5,
                          paddingInline: 20,
                          borderRadius: 5,
                          border: "none",
                          backgroundColor: "green",
                          color: "white",
                          fontWeight: "600",
                          marginTop: 20,
                          marginRight: 50,
                        }}
                      >
                        Evet
                      </button>
                      <button
                        onClick={() => setShowModal(false)}
                        style={{
                          paddingBlock: 5,
                          paddingInline: 20,
                          borderRadius: 5,
                          border: "none",
                          backgroundColor: "red",
                          color: "white",
                          fontWeight: "600",
                          marginTop: 20,
                        }}
                      >
                        Hayır
                      </button>
                    </div>
                  )}
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={showResultModal}
              onHide={() => setShowResultModal(false)}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ textAlign: "center", width: "100%" }}>
                  {processResult === "success" ? "Başarılı" : "Hata"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    marginBlock: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <text>
                    {processResult === "success"
                      ? "İşleminiz başarıyla gerçekleştirilmiştir."
                      : "İşleminiz gerçekleştirilirken bir hata meydana geldi."}
                  </text>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    window.location.reload();
                    setShowResultModal(false);
                    setShowModal(false);
                  }}
                >
                  Kapat
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </Layout>
    </div>
  );
}

export default DetailPage;
