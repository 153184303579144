import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Form } from "react-bootstrap";
import Services from "../../services/Services";
import { MdOutlineCancel } from "react-icons/md";
import "./receivingpage.scss";

function ReceivingPage() {
  // const navigate = useNavigate();
  const width = window.innerWidth;
  const [showAlert, setShowAlert] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  // datas
  const [token, setToken] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  // fields
  const [search, setSearch] = useState();

  function handleClose() {
    setShowAlert(false);
  }

  const addReceivedOrders = () => {
    setButtonLoading(true);
    Services.SetInBranch(
      token,
      filteredList.map((x) => x.id)
    )
      .then((response) => {
        if (response.data.status) {
          window.alert("Listedeki paketler zimmetinize geçmiştir.");
          setFilteredList([]);
          setSearch("");
          setButtonLoading(false);
        } else {
          setButtonLoading(false);
          window.alert(
            "Listedeki paketler zimmetinize geçirilirken hata oluştu."
          );
        }
      })
      .catch((error) => {
        setButtonLoading(false);
        window.alert(
          "Listedeki paketler zimmetinize geçirilirken hata oluştu."
        );
        console.log(error);
      });
  };

  function applySortFilter(search) {
    if (search) {
      setAddLoading(true);
      const storedToken = localStorage.getItem("token");
      setToken(storedToken);
      Services.GetPostByBarcode(storedToken, [search], [3, 5, 9])
        .then((response) => {
          if (response.data.status) {
            const foundItem = response.data.result[0];
            if (foundItem.status === "3") {
              const foundBarcode = foundItem.barcode;
              const isAlreadyInList = filteredList.some(
                (item) => item.barcode === foundBarcode
              );
              if (!isAlreadyInList) {
                setFilteredList((prevList) => prevList.concat(foundItem));
                setSearch("");
              } else {
                window.alert(
                  "Listede eklenmek istenen barkoda ait gönderi zaten bulunmakta."
                );
                setSearch("");
              }
            } else {
              window.alert("Gönderi zaten daha önce zimmetinize alınmıştır.");
              setSearch("");
            }
          } else {
            alert("Gönderi bulunamadı, barkodunuzu kontrol ediniz.");
            setSearch("");
          }
        })
        .catch((error) => {
          alert("Gönderi bulunamadı, barkodunuzu kontrol ediniz.");
          console.log(error);
          setSearch("");
        })
        .finally(() => setAddLoading(false));
    }
  }

  function removeSelectedElement(element) {
    let index = filteredList.indexOf(element);
    let arr = [];
    arr = arr.concat(filteredList);
    arr.splice(index, 1);
    setFilteredList(arr);
  }

  // const fetchDatas = () => {
  //   const storedToken = localStorage.getItem("token");
  //   setToken(storedToken);
  //   Services.GetPosts(storedToken, [3, 5, 9])
  //     .then((res) => {
  //       if (res.data.status) {
  //         console.log(res.data.result);
  //         setOrderList(res.data.result);
  //       } else {
  //         setOrderList([]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setOrderList([]);
  //     });
  // };

  // useEffect(() => {
  //   fetchDatas();
  // }, []);

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            nextTitle={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <div className="receiving">
            <h5>Teslim Al</h5>
            <div className="input-container">
              <Form.Control
                type="text"
                style={{ height: 50 }}
                placeholder="Barkod"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                disabled={addLoading}
                className="search"
                onClick={() => applySortFilter(search)}
              >
                {addLoading ? "Aranıyor..." : "Bul"}
              </Button>
              {filteredList && (
                <>
                  {filteredList.map((order, index) => (
                    <div className="card" key={index}>
                      <div>
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <p className="title">
                            {order?.takenName[0]}***{" "}
                            {order?.takenName.split(" ")[1][0]}***
                          </p>
                          <p className="api-type">({order?.apiUserType})</p>
                        </div>{" "}
                        <p className="sub-title">{order?.barcode}</p>
                      </div>

                      <div
                        onClick={() => removeSelectedElement(order)}
                        className="cancel-button"
                      >
                        <MdOutlineCancel size={24} />
                      </div>
                    </div>
                  ))}
                </>
              )}
              <div className="code-field">
                <Button
                  disabled={buttonLoading}
                  style={{ backgroundColor: "#DA3831", border: "none" }}
                  className="complete-button"
                  onClick={() => addReceivedOrders()}
                >
                  {buttonLoading ? "Teslim Alınıyor..." : "Teslim Al"}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Modal show={showAlert} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Uyarı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {search !== ""
              ? "Gönderi bulunamadı, barkodunuzu kontrol ediniz."
              : "Lütfen barkod giriniz."}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showInfo} onHide={() => setShowInfo(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Şifre Nasıl Bulunur</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src="https://easypointapi.com/files/esnaf-kodsoru-202310031532.html"
              title="External Content"
              width="100%"
              height="150px"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowInfo(false)}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
}

export default ReceivingPage;
