// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.malls-container {
  max-width: 1000px;
  display: flex;
  margin-inline: auto;
  margin-block: 50px;
  flex-direction: column;
}
.malls-container .row {
  width: -webkit-fill-available;
}`, "",{"version":3,"sources":["webpack://./src/pages/mallspage/mallspage.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;AACF;AAAE;EACE,6BAAA;AAEJ","sourcesContent":[".malls-container {\r\n  max-width: 1000px;\r\n  display: flex;\r\n  margin-inline: auto;\r\n  margin-block: 50px;\r\n  flex-direction: column;\r\n  .row {\r\n    width: -webkit-fill-available;\r\n  }\r\n  //   .card {\r\n  //     height: 120px;\r\n  //   }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
