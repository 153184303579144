import React, { useEffect, useState } from "react";
import { Button, Col, Card, Row, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import "./mallspage.scss";
import Services from "../../services/Services";
import { StatusList } from "../../types/statusTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

function MallsPage() {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [mallList, setMallList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    Services.GetMalls(token)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) setMallList(res.data.result);
        else setMallList([]);
      })
      .catch((err) => {
        setMallList([]);
        console.log(err);
      });
  }, []);

  const handleNewClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"AVM LİSTESİ"}
            back={"reset"}
            action={(e) => null}
          />
        )}
        <div className="malls-container">
          <h4 style={{ textAlign: "center", marginBottom: 20 }}>AVM Listesi</h4>
          <Button
            style={{ fontWeight: 600, alignSelf: "flex-end", marginBottom: 20 }}
            variant="success"
            onClick={handleNewClick}
          >
            <FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: 8 }} />
            Yeni Ekle
          </Button>
          <Row className="mb-5">
            {mallList.map((mall, index) => (
              <Col key={index} sm={12} md={6} lg={6} xl={6}>
                <Card className="my-2">
                  <Card.Title
                    style={{
                      marginTop: 15,
                      textAlign: "center",
                    }}
                  >
                    {mall.title}
                  </Card.Title>
                  <Card.Body style={{ textAlign: "center" }}>
                    <Card.Text>
                      {mall.county} / {mall.city.toUpperCase()}
                    </Card.Text>
                    <Button style={{ fontWeight: 600 }} variant="danger">
                      Mağazalarını Gör
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Layout>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Yeni Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Responsive form inside modal */}
          <Form>
            {/* Add your form fields here */}
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" placeholder="Enter title" />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
              />
            </Form.Group>
            {/* Add more form fields as needed */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MallsPage;
