import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Col, Form, iframe } from "react-bootstrap";
import Services from "../../services/Services";
import { MdOutlineCancel } from "react-icons/md";
import "./givingbackpage.scss";

function GivingBackPage() {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [showAlert, setShowAlert] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  // datas
  const [token, setToken] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  // fields
  const [search, setSearch] = useState("");
  const [code, setCode] = useState("");

  function handleClose() {
    setShowAlert(false);
  }

  function applySortFilter(search) {
    if (search) {
      const foundItem = orderList.find(
        (_user) => _user.barcode.toLowerCase() === search.toLowerCase()
      );
      if (foundItem && foundItem.status === "9") {
        const isAlreadyInList = filteredList.some(
          (item) => item.barcode.toLowerCase() === search.toLowerCase()
        );

        if (isAlreadyInList) {
          window.alert("Gönderi zaten listede.");
          setSearch("");
        } else {
          setFilteredList((prevList) => prevList.concat(foundItem));
          setSearch("");
        }
      } else {
        window.alert("Lütfen önce barkod giriniz.");
      }
    }
  }

  function removeSelectedElement(element) {
    let index = filteredList.indexOf(element);
    let arr = [];
    arr = arr.concat(filteredList);
    arr.splice(index, 1);
    setFilteredList(arr);
  }

  const fetchDatas = () => {
    const storedToken = localStorage.getItem("token");
    setToken(storedToken);
    console.log(storedToken);
    Services.GetPosts(storedToken, [9])
      .then((res) => {
        if (res.data.status) {
          setOrderList(res.data.result);
        } else {
          setOrderList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setOrderList([]);
      });
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            nextTitle={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <div className="giving-back">
            <h5>İade Et</h5>
            <div className="input-container">
              <Form.Control
                type="text"
                style={{ height: 50 }}
                placeholder="Barkod"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                className="search"
                onClick={() => applySortFilter(search)}
              >
                Bul
              </Button>
              {filteredList && (
                <>
                  {filteredList.map((order, index) => (
                    <div className="card" key={index}>
                      <div>
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <p className="title">
                            {order?.takenName[0]}***{" "}
                            {order?.takenName.split(" ")[1][0]}***
                          </p>
                          <p className="api-type">({order?.apiUserType})</p>
                        </div>{" "}
                        <p className="sub-title">{order?.barcode}</p>
                      </div>

                      <div
                        onClick={() => removeSelectedElement(order)}
                        className="cancel-button"
                      >
                        <MdOutlineCancel size={24} />
                      </div>
                    </div>
                  ))}
                </>
              )}
              <div
                className="code-field"
                style={{ marginBottom: filteredList?.length > 0 ? 100 : 250 }}
              >
                {filteredList?.length > 0 && (
                  <Button
                    style={{ backgroundColor: "#DA3831", border: "none" }}
                    className="complete-button"
                    onClick={() => null}
                  >
                    ({filteredList?.length}) adet Paketi İade Et
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal show={showAlert} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Uyarı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {search !== ""
              ? "Gönderi bulunamadı, barkodunuzu kontrol ediniz."
              : "Lütfen barkod giriniz."}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
}

export default GivingBackPage;
