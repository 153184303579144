// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #ddd; /* Add border color */
  border-radius: 10px; /* Add border radius */
}

.table th,
.table td {
  border: 1px solid #ddd; /* Add border color */
  padding: 10px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2; /* Add background color to header */
}

.table tbody tr:hover {
  background-color: #f5f5f5; /* Add hover effect */
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #da3831;
  border-color: #da3831;
}

.pagination {
  --bs-pagination-color: #da3831;
  --bs-pagination-hover-color: #da3831;
}`, "",{"version":3,"sources":["webpack://./src/pages/pastpostspage/pastpostspage.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,sBAAA,EAAA,qBAAA;EACA,mBAAA,EAAA,sBAAA;AACF;;AAEA;;EAEE,sBAAA,EAAA,qBAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,yBAAA,EAAA,mCAAA;AACF;;AAEA;EACE,yBAAA,EAAA,qBAAA;AACF;;AAEA;;EAEE,UAAA;EACA,wCAAA;EACA,yBAAA;EACA,qBAAA;AACF;;AAEA;EACE,8BAAA;EACA,oCAAA;AACF","sourcesContent":[".table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  margin-top: 20px;\r\n  border: 1px solid #ddd; /* Add border color */\r\n  border-radius: 10px; /* Add border radius */\r\n}\r\n\r\n.table th,\r\n.table td {\r\n  border: 1px solid #ddd; /* Add border color */\r\n  padding: 10px;\r\n  text-align: left;\r\n}\r\n\r\n.table th {\r\n  background-color: #f2f2f2; /* Add background color to header */\r\n}\r\n\r\n.table tbody tr:hover {\r\n  background-color: #f5f5f5; /* Add hover effect */\r\n}\r\n\r\n.active > .page-link,\r\n.page-link.active {\r\n  z-index: 3;\r\n  color: var(--bs-pagination-active-color);\r\n  background-color: #da3831;\r\n  border-color: #da3831;\r\n}\r\n\r\n.pagination {\r\n  --bs-pagination-color: #da3831;\r\n  --bs-pagination-hover-color: #da3831;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
