import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Pagination, Spinner, Modal, Button } from "react-bootstrap";
import "./handsfree.scss";
import Services from "../../services/Services";
import moment from "moment";
import { StatusList } from "../../types/statusTypes";

function HandsfreePage() {
  const width = window.innerWidth;
  const data = [
    { id: 1, title: "Post 1", date: "2023-01-01" },
    { id: 2, title: "Post 2", date: "2023-01-02" },
  ];
  const [allDatas, setAllDatas] = useState([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeliverModal, setShowDeliverModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [showFailureDeliverModal, setShowFailureDeliverModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [otpCode, setOtpCode] = useState("");
  const [deliveryPassword, setDeliveryPassword] = useState("");
  const [sortByDateAsc, setSortByDateAsc] = useState(false);
  const [sortByStore, setSortByStore] = useState(false);
  const [sortByOrderNumber, setSortByOrderNumber] = useState(false);
  const [sortByStatus, setSortByStatus] = useState(false);
  const [sortByAdditionalNote, setSortByAdditionalNote] = useState(false);
  const [originalData, setOriginalData] = useState([]);

  const [showAdditionalNoteFilter, setShowAdditionalNoteFilter] =
    useState(false);
  const [filterAdditionalNoteText, setFilterAdditionalNoteText] = useState("");

  const [showBranchTitleFilter, setShowBranchTitleFilter] = useState(false);
  const [filterBranchTitleText, setFilterBranchTitleText] = useState("");

  const [showOrderNumberFilter, setShowOrderNumberFilter] = useState(false);
  const [filterOrderNumberText, setFilterOrderNumberText] = useState("");

  const [showDateFilter, setShowDateFilter] = useState(false);
  const [filterDateValue, setFilterDateValue] = useState(null);

  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [filterStatusValue, setFilterStatusValue] = useState("");

  const [currentStatuses, setCurrentStatuses] = useState([]);

  // for pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 20;
  const totalPages = Math.ceil(allDatas.length / itemsPerPage);
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const displayedData = allDatas.slice(indexOfFirstItem, indexOfLastItem);

  const toggleShowBranchTitleFilter = () => {
    setShowBranchTitleFilter(true);
    toggleCloseAdditionalNoteFilter();
    toggleCloseOrderNumberFilter();
    toggleCloseDateFilter();
    toggleCloseStatusFilter();
  };
  const toggleCloseBranchTitleFilter = () => {
    setAllDatas(originalData);
    setShowBranchTitleFilter(false);
    setFilterBranchTitleText("");
  };

  const filterByBranchTitle = (value) => {
    setFilterBranchTitleText(value);
    var filteredData;
    if (value === "") {
      setAllDatas(originalData);
    } else {
      filteredData = originalData.filter((item) =>
        item.branchTitle.toLowerCase().includes(value.toLowerCase())
      );
      setAllDatas(filteredData);
    }
  };

  const toggleShowOrderNumberFilter = () => {
    setShowOrderNumberFilter(true);
    toggleCloseAdditionalNoteFilter();
    toggleCloseBranchTitleFilter();
    toggleCloseDateFilter();
    toggleCloseStatusFilter();
  };
  const toggleCloseOrderNumberFilter = () => {
    setAllDatas(originalData);
    setShowOrderNumberFilter(false);
    setFilterOrderNumberText("");
  };

  const filterByOrderNumber = (value) => {
    setFilterOrderNumberText(value);
    var filteredData;
    if (value === "") {
      setAllDatas(originalData);
    } else {
      filteredData = originalData.filter((item) =>
        item.orderNumber.toLowerCase().includes(value.toLowerCase())
      );
      setAllDatas(filteredData);
    }
  };

  const toggleShowAdditionalNoteFilter = () => {
    setShowAdditionalNoteFilter(true);
    toggleCloseBranchTitleFilter();
    toggleCloseOrderNumberFilter();
    toggleCloseDateFilter();
    toggleCloseStatusFilter();
  };
  const toggleCloseAdditionalNoteFilter = () => {
    setAllDatas(originalData);
    setShowAdditionalNoteFilter(false);
    setFilterAdditionalNoteText("");
  };

  const filterByAdditionalNote = (value) => {
    setFilterAdditionalNoteText(value);
    var filteredData;
    if (value === "") {
      setAllDatas(originalData);
    } else {
      filteredData = originalData.filter((item) =>
        item.additionalNote.toLowerCase().includes(value.toLowerCase())
      );
      setAllDatas(filteredData);
    }
  };

  const handleDateChange = (value) => {
    const cleanedValue = value.replace(/[^0-9]/g, "");

    let formattedValue = "";
    if (cleanedValue.length > 2) {
      formattedValue += cleanedValue.substr(0, 2) + ".";
      if (cleanedValue.length > 4) {
        formattedValue += cleanedValue.substr(2, 2) + ".";
        if (cleanedValue.length > 6) {
          formattedValue += cleanedValue.substr(4, 4);
        } else {
          formattedValue += cleanedValue.substr(4);
        }
      } else {
        formattedValue += cleanedValue.substr(2);
      }
    } else {
      formattedValue = cleanedValue;
    }

    // Giriş alanı değerini güncelle
    setFilterDateValue(formattedValue);

    var filteredData;
    if (value === null || value === "") {
      setAllDatas(originalData);
    } else {
      filteredData = originalData.filter((item) => {
        const formattedDate1 = `${(
          "0" + new Date(new Date(item.createdDateTime * 1000)).getDate()
        ).slice(-2)}.${(
          "0" +
          (new Date(new Date(item.createdDateTime * 1000)).getMonth() + 1)
        ).slice(-2)}.${new Date(
          new Date(item.createdDateTime * 1000)
        ).getFullYear()}`;
        const date2 = formattedValue;
        return formattedDate1.includes(date2);
      });
      setAllDatas(filteredData);
    }
  };

  const toggleShowDateFilter = () => {
    setShowDateFilter(true);
    toggleCloseAdditionalNoteFilter();
    toggleCloseOrderNumberFilter();
    toggleCloseBranchTitleFilter();
    toggleCloseStatusFilter();
  };
  const toggleCloseDateFilter = () => {
    setAllDatas(originalData);
    setShowDateFilter(false);
    setFilterDateValue(null);
  };

  // const filterByDate = (value) => {
  //   setFilterDateValue(value)
  //   var filteredData;
  //   if (value === null || value === "") {
  //     setAllDatas(originalData)
  //   }
  //   else {
  //     // filteredData = originalData.filter(item => new Date(new Date(item.createdDateTime * 1000).setSeconds(0)).getTime() === new Date(value).getTime());
  //     console.log(new Date(new Date(originalData[0].createdDateTime * 1000)).toUTCString())
  //     filteredData = originalData.filter(item => new Date(new Date(item.createdDateTime * 1000)).toString().includes(value));
  //     setAllDatas(filteredData)
  //   }
  // }

  const getStatuses = (items) => {
    const uniqueStatuses = [...new Set(items.map((item) => item.status))];
    return uniqueStatuses;
  };

  const getStatusLabel = (statusId) => {
    const foundStatus = StatusList.find(
      (status) => status.id === parseInt(statusId)
    );
    return foundStatus
      ? foundStatus
      : { id: statusId, label: "Bilinmeyen Durum" };
  };

  const toggleShowStatusFilter = () => {
    console.log(currentStatuses);
    setShowStatusFilter(true);
    toggleCloseAdditionalNoteFilter();
    toggleCloseOrderNumberFilter();
    toggleCloseBranchTitleFilter();
    toggleCloseDateFilter();
  };
  const toggleCloseStatusFilter = () => {
    setAllDatas(originalData);
    setShowStatusFilter(false);
    setFilterStatusValue("");
  };

  const filterByStatus = (value) => {
    setFilterStatusValue(value.target.value);
    console.log(value.target.value);
    var filteredData;
    if (value === null || value === "" || value.target.value === "0") {
      setAllDatas(originalData);
      console.log("baba naber");
    } else {
      filteredData = originalData.filter(
        (item) => item.status === value.target.value
      );
      setAllDatas(filteredData);
      console.log("baba naber2");
    }
  };

  const toggleSortByAdditionalNote = () => {
    const newSortByAdditionalNote = !sortByAdditionalNote;
    setSortByAdditionalNote(newSortByAdditionalNote);
    console.log(newSortByAdditionalNote);
    const sortedData = [...allDatas].sort((a, b) => {
      if (newSortByAdditionalNote) {
        return a.additionalNote.localeCompare(b.additionalNote);
      } else {
        return b.additionalNote.localeCompare(a.additionalNote);
      }
    });
    setAllDatas(sortedData);
    setSortByDateAsc(false);
    setSortByOrderNumber(false);
    setSortByStatus(false);
    setSortByStore(false);
  };

  const toggleSortByDate = () => {
    const newSortByDateAsc = !sortByDateAsc;
    setSortByDateAsc(newSortByDateAsc);
    const sortedOrders = [...allDatas].sort((a, b) => {
      if (newSortByDateAsc) {
        return a.createdDateTime - b.createdDateTime;
      } else {
        return b.createdDateTime - a.createdDateTime;
      }
    });
    setAllDatas(sortedOrders);
    setSortByStore(false);
    setSortByOrderNumber(false);
    setSortByStatus(false);
    setSortByAdditionalNote(false);
  };

  const toggleSortByStore = () => {
    const newSortByStore = !sortByStore;
    setSortByStore(newSortByStore);
    const sortedData = [...allDatas].sort((a, b) => {
      if (newSortByStore) {
        return a.branchTitle.localeCompare(b.branchTitle);
      } else {
        return b.branchTitle.localeCompare(a.branchTitle);
      }
    });
    setAllDatas(sortedData);
    setSortByDateAsc(false);
    setSortByOrderNumber(false);
    setSortByStatus(false);
    setSortByAdditionalNote(false);
  };

  const toggleSortOrderNumber = () => {
    const newSortByOrderNumber = !sortByOrderNumber;
    setSortByOrderNumber(newSortByOrderNumber);
    const sortedData = [...allDatas].sort((a, b) => {
      if (newSortByOrderNumber) {
        return a.orderNumber.localeCompare(b.orderNumber);
      } else {
        return b.orderNumber.localeCompare(a.orderNumber);
      }
    });
    setAllDatas(sortedData);
    setSortByDateAsc(false);
    setSortByStore(false);
    setSortByStatus(false);
    setSortByAdditionalNote(false);
  };

  const toggleSortStatus = () => {
    const newSortByStatus = !sortByStatus;
    setSortByStatus(newSortByStatus);
    const sortedData = [...allDatas].sort((a, b) => {
      if (newSortByStatus) {
        return a.status - b.status;
      } else {
        return b.status - a.status;
      }
    });
    setAllDatas(sortedData);
    setSortByDateAsc(false);
    setSortByStore(false);
    setSortByOrderNumber(false);
    setSortByAdditionalNote(false);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("DD.MM.YYYY HH:mm");
  };

  const findStatusTitle = (status) => {
    const item = StatusList.find((row) => row.id === +status);
    return item.label;
  };

  const handleClose = (type) => {
    if (type === "received") {
      setShowModal(false);
    } else {
      setShowDeliverModal(false);
    }
    setSelectedItem(null);
    fetchDatas();
  };
  const handleShow = (type) => {
    if (type === "received") {
      setShowModal(true);
    } else {
      setShowDeliverModal(true);
    }
  };

  const handleReceived = (id) => {
    setSelectedItem(id);
    handleShow("received");
  };

  const handleDelivered = (item) => {
    setSelectedItem(item.id);
    setDeliveryPassword(item.deliveryPassword);
    setSelectedPost(item);
    handleShow("delivered");
  };

  const handleComplete = (type) => {
    setButtonLoading(true);
    if (type === "received") {
      Services.SetInBranch(token, selectedItem)
        .then((res) => {
          console.log("response: ", res.data);
          if (res.data.status) {
            setShowModal(false);
            handleClose("received");
            fetchDatas();
            setSelectedItem(null);
            setShowSuccessModal(true);
          } else {
            setShowModal(false);
            setShowFailureModal(true);
          }
        })
        .catch((err) => {
          setShowModal(false);
          setShowFailureModal(true);
        })
        .finally(() => setButtonLoading(false));
    } else {
      console.log("datalar: ", selectedPost.postID, otpCode);
      Services.PostComplete(token, selectedPost.postID, otpCode)
        .then((res) => {
          console.log("response: ", res.data);
          if (res.data.status) {
            setShowDeliverModal(false);
            handleClose("deliver");
            fetchDatas();
            setSelectedItem(null);
            setShowSuccessModal(true);
          } else {
            setShowDeliverModal(false);
            setShowFailureDeliverModal(true);
          }
        })
        .catch((err) => {
          setShowDeliverModal(false);
          setShowFailureDeliverModal(true);
        })
        .finally(() => setButtonLoading(false));
    }
  };
  const fetchDatas = () => {
    setLoading(true);
    const storedToken = localStorage.getItem("token");
    setToken(storedToken);
    Services.GetPosts(storedToken, [1, 2, 3, 4, 5, 6, 7, 8, 9])
      .then((res) => {
        console.log("response: ", res.data);
        if (res.data.status) {
          setAllDatas(
            res.data.result.sort(
              (a, b) => b.createdDateTime - a.createdDateTime
            )
          );
          setOriginalData(
            res.data.result.sort(
              (a, b) => b.createdDateTime - a.createdDateTime
            )
          );
          const uniqueStatuses = getStatuses(res.data.result);
          var currentStatusesInFunc = [];
          for (let i = 0; i < uniqueStatuses.length; i++) {
            currentStatusesInFunc.push(getStatusLabel(uniqueStatuses[i]));
          }
          setCurrentStatuses(currentStatusesInFunc);
        } else {
          setAllDatas([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setAllDatas([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <h5 className="text-center my-5">Handsfree Gönderileri</h5>

          {loading && (
            <div
              style={{
                display: "flex",
                height: "50vh",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 30,
              }}
            >
              <Spinner
                animation="border"
                role="status"
                style={{
                  borderBottomColor: "rgb(32, 101, 209)",
                  borderRightColor: "rgb(32, 101, 209)",
                  borderLeftColor: "rgb(32, 101, 209)",
                  borderTopColor: "white",
                }}
              />
            </div>
          )}

          {/* Data Table */}
          {!loading && originalData.length === 0 && (
            <p className="text-center">Geçmiş gönderi bulunamamıştır.</p>
          )}

          {/* Table */}
          {!loading && originalData?.length > 0 && (
            <>
              <table style={{ marginBottom: 20 }} className="table">
                <thead>
                  <tr>
                    <th style={{ width: 20, textAlign: "center" }}>No</th>

                    {showBranchTitleFilter ? (
                      <th style={{ textAlign: "center", width: 50 }}>
                        <input
                          value={filterBranchTitleText}
                          onChange={(e) => filterByBranchTitle(e.target.value)}
                          type="text"
                          placeholder="Mağaza"
                        />
                        <span
                          onClick={toggleCloseBranchTitleFilter}
                          style={{
                            fontSize: "25px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        >
                          &times;
                        </span>
                      </th>
                    ) : (
                      <th style={{ textAlign: "center", width: 200 }}>
                        <span>
                          Mağaza{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={toggleSortByStore}
                          >
                            &#8595;&#8593;
                          </span>
                        </span>
                        <span
                          style={{ float: "right", cursor: "pointer" }}
                          onClick={toggleShowBranchTitleFilter}
                        >
                          &#128269;
                        </span>
                      </th>
                    )}

                    {showOrderNumberFilter ? (
                      <th style={{ textAlign: "center", width: 50 }}>
                        <input
                          value={filterOrderNumberText}
                          onChange={(e) => filterByOrderNumber(e.target.value)}
                          type="text"
                          placeholder="Paket Kodu"
                        />
                        <span
                          onClick={toggleCloseOrderNumberFilter}
                          style={{
                            fontSize: "25px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        >
                          &times;
                        </span>
                      </th>
                    ) : (
                      <th style={{ textAlign: "center", width: 150 }}>
                        <span>
                          {" "}
                          Paket Kodu{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={toggleSortOrderNumber}
                          >
                            &#8595;&#8593;
                          </span>
                        </span>
                        <span
                          style={{ float: "right", cursor: "pointer" }}
                          onClick={toggleShowOrderNumberFilter}
                        >
                          &#128269;
                        </span>
                      </th>
                    )}

                    {showAdditionalNoteFilter ? (
                      <th style={{ textAlign: "center", width: 50 }}>
                        <input
                          value={filterAdditionalNoteText}
                          onChange={(e) =>
                            filterByAdditionalNote(e.target.value)
                          }
                          type="text"
                          placeholder="Not"
                        />
                        <span
                          onClick={toggleCloseAdditionalNoteFilter}
                          style={{
                            fontSize: "25px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        >
                          &times;
                        </span>
                      </th>
                    ) : (
                      <th style={{ textAlign: "center", width: 50 }}>
                        <span>
                          {" "}
                          Not{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={toggleSortByAdditionalNote}
                          >
                            &#8595;&#8593;
                          </span>
                        </span>
                        <span
                          style={{ float: "right", cursor: "pointer" }}
                          onClick={toggleShowAdditionalNoteFilter}
                        >
                          &#128269;
                        </span>
                      </th>
                    )}

                    {showDateFilter ? (
                      <th style={{ textAlign: "center", width: 50 }}>
                        <input
                          maxLength="10"
                          value={filterDateValue}
                          onChange={(e) => handleDateChange(e.target.value)}
                          type="text"
                          placeholder="GG.AA.YYYY"
                        />
                        <span
                          onClick={toggleCloseDateFilter}
                          style={{
                            fontSize: "25px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        >
                          &times;
                        </span>
                      </th>
                    ) : (
                      <th style={{ textAlign: "center", width: 150 }}>
                        <span>
                          Tarih{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={toggleSortByDate}
                          >
                            &#8595;&#8593;
                          </span>
                        </span>
                        <span
                          style={{ float: "right", cursor: "pointer" }}
                          onClick={toggleShowDateFilter}
                        >
                          &#128269;
                        </span>
                      </th>
                    )}

                    {showStatusFilter ? (
                      <th style={{ textAlign: "center", width: 50 }}>
                        <select
                          value={filterStatusValue}
                          onChange={filterByStatus}
                        >
                          <option value="0">Seçiniz</option>
                          {currentStatuses.map((currentStatus) => (
                            <option
                              key={currentStatus.id}
                              value={currentStatus.id}
                            >
                              {currentStatus.label}
                            </option>
                          ))}
                        </select>
                        <span
                          onClick={toggleCloseStatusFilter}
                          style={{
                            fontSize: "25px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        >
                          &times;
                        </span>
                      </th>
                    ) : (
                      <th style={{ textAlign: "center", width: 150 }}>
                        <span>
                          Durum{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={toggleSortStatus}
                          >
                            &#8595;&#8593;
                          </span>
                        </span>
                        <span
                          style={{ float: "right", cursor: "pointer" }}
                          onClick={toggleShowStatusFilter}
                        >
                          &#128269;
                        </span>
                      </th>
                    )}

                    <th style={{ textAlign: "center", width: 50 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {displayedData?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          width: 20,
                          textAlign: "center",
                          fontWeight: "600",
                        }}
                      >
                        {(page - 1) * itemsPerPage + index + 1}
                      </td>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {item.branchTitle}
                      </td>
                      <td style={{ textAlign: "center", width: 150 }}>
                        {item.orderNumber}
                      </td>
                      <td style={{ textAlign: "center", width: 50 }}>
                        {item.additionalNote}
                      </td>
                      <td style={{ textAlign: "center", width: 150 }}>
                        {moment
                          .unix(item.createdDateTime)
                          .format("DD.MM.YYYY HH:mm")}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          width: 150,
                          color: item.status === "1" ? "green" : "orange",
                        }}
                      >
                        {findStatusTitle(item.status)}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          width: 100,
                          color: "rgb(32, 101, 209)",
                          fontWeight: "600",
                        }}
                      >
                        {(item.status === "4" || item.status === "5") && (
                          <button
                            className="received-button"
                            style={{
                              display:
                                (item.apiUserType !== "handsfree" ||
                                  item.status === "1") &&
                                "none",
                            }}
                            onClick={() => {
                              if (item.status === "4") {
                                handleReceived(item.id);
                              } else {
                                handleDelivered(item);
                              }
                            }}
                          >
                            {item.apiUserType === "handsfree" &&
                              (item.status === "4"
                                ? "Teslim Al"
                                : item.status === "5"
                                ? "Teslim Et"
                                : "")}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Pagination */}
              <Pagination
                className="pagination-container"
                style={{ float: "right", marginBottom: 100 }}
              >
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === page}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </>
          )}

          {!loading && originalData.length !== 0 && allDatas.length === 0 && (
            <p className="text-center" style={{ color: "gray" }}>
              Filtreye uygun gönderi bulunamamıştır.
            </p>
          )}
        </div>

        <Modal centered show={showModal} onHide={() => handleClose("received")}>
          <Modal.Header closeButton>
            <Modal.Title>Teslim Al</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedItem && (
              <>
                <p>{`Teslim alabilmek için QR kodunu okutturunuz.`}</p>
                <div style={{ textAlign: "center" }}>
                  <img
                    src="https://qr.easypointapi.com?chs=500x500&cht=qr&chl=5378557442f5378557394s237401561f0237401505s6032497935f6032497886s2647941852f2647941800s1072584083f1072584032s827242094f0827242041s8346233000f8346232945s8249971250f8249971198s6839794263f6839794212s64859117f0064859062s6734395365f6734395308s9217467747f9217467692s5109084706f5109084657s2055401963f2055401913s5682630380f5682630327s9246609120f9246609066s9171105320f9171105268s5652237277f5652237229s7436536911f7436536863s1372943991f1372943941:1413783387f1413783272:5006094420f5006094371s7623326511f7623326455s8402022847f8402022790s6350156047f6350155994s7546915972f7546915923s8008509669f8008509617s9164871434f9164871383s6351770343f6351770291s4381660039f4381659984s919246518f0919246466:2344917800f2344917692s211755846f0211755735s7266896378f7266896279s8967686487f8967686390s7333251943f7333251827s9161647204f9161647099s3158696556f3158696445s7893615827f7893615717"
                    alt="QR Code"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </>
            )}
          </Modal.Body>
          {/* <Modal.Footer>
            <Button
              disabled={buttonLoading}
              variant="success"
              onClick={() => handleComplete("received")}
            >
              {buttonLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Teslim Al"
              )}
            </Button>
            <Button
              disabled={buttonLoading}
              variant="secondary"
              onClick={() => handleClose("received")}
            >
              Kapat
            </Button>
          </Modal.Footer> */}
        </Modal>

        <Modal show={showDeliverModal} onHide={() => handleClose("deliver")}>
          <Modal.Header closeButton>
            <Modal.Title>Teslim Et</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedItem && (
              <>
                <p>{`Bu gönderiyi teslim etmek istediğinize emin misiniz?`}</p>
                <div className="mb-3">
                  <label htmlFor="otpCode" className="form-label">
                    OTP Kodu:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="otpCode"
                    name="otpCode"
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                  />
                  {/* <p>{deliveryPassword}</p> */}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={buttonLoading}
              variant="success"
              onClick={() => handleComplete("deliver")}
            >
              {buttonLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Teslim Et"
              )}
            </Button>
            <Button
              disabled={buttonLoading}
              variant="secondary"
              onClick={() => handleClose("deliver")}
            >
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showFailureModal}
          onHide={() => setShowFailureModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Hata</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Paket henüz teslim alınamaz. Porter işlemleri tamamlanmadı.`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowFailureModal(false)}
            >
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showFailureDeliverModal}
          onHide={() => setShowFailureDeliverModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Hata</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Paket teslim edilirken bir hata oluştu.`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowFailureDeliverModal(false);
              }}
            >
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showSuccessModal}
          onHide={() => setShowSuccessModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Başarılı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`İşleminiz gerçekleştirilmiştir.`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowSuccessModal(false)}
            >
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
}

export default HandsfreePage;
