import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Pagination, Spinner, Modal, Button } from "react-bootstrap";
import "./cloakroompage.scss";
import Services from "../../services/Services";
import moment from "moment";

function CloakroomPage() {
  const width = window.innerWidth;
  const [token, setToken] = useState("");
  // modals & loadings
  const [loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  // datas
  const [allDatas, setAllDatas] = useState([
    {
      name: "Batuhan",
      lastname: "Göktaş",
      phone: "05564565645",
      cloakroomNo: "1",
      pieceQuantity: "1",
      createdDateTime: "1685024163",
      status: "1",
    },
    {
      name: "Batuhan",
      lastname: "Göktaş",
      phone: "05564565645",
      cloakroomNo: "2",
      pieceQuantity: "4",
      createdDateTime: "1685024163",
      status: "21",
    },
    {
      name: "Batuhan",
      lastname: "Göktaş",
      phone: "05564565645",
      cloakroomNo: "3",
      pieceQuantity: "5",
      createdDateTime: "1685024163",
      status: "1",
    },
    {
      name: "Batuhan",
      lastname: "Göktaş",
      phone: "05564565645",
      cloakroomNo: "4",
      pieceQuantity: "1",
      createdDateTime: "1685024163",
      status: "16",
    },
    {
      name: "Batuhan",
      lastname: "Göktaş",
      phone: "05564565645",
      cloakroomNo: "5",
      pieceQuantity: "1",
      createdDateTime: "1685024163",
      status: "1",
    },
  ]);
  const [addForm, setAddForm] = useState({
    phone: "",
    cloakroomNo: "",
    pieceQuantity: "",
    note: "",
  });
  // for pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 20;
  const totalPages = Math.ceil(allDatas.length / itemsPerPage);
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const displayedData = allDatas.slice(indexOfFirstItem, indexOfLastItem);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setAddForm((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSave = () => {
    console.log("Form Data:", addForm);

    setAddForm({
      phone: "",
      cloakroomNo: "",
      pieceQuantity: "",
      note: "",
    });

    setAddModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("DD.MM.YYYY HH:mm");
  };

  const fetchDatas = () => {
    setLoading(true);
    const storedToken = localStorage.getItem("token");
    setToken(storedToken);
    Services.GetPosts(storedToken, [1, 16, 21])
      .then((res) => {
        if (res.data.status) {
          setAllDatas(res.data.result);
        } else {
          setAllDatas([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setAllDatas([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //   useEffect(() => {
  //     fetchDatas();
  //   }, []);

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            // nextTitle={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <h5 className="text-center">Vestiyer Listesi</h5>
          <button
            onClick={() => setAddModal(true)}
            style={{
              backgroundColor: "#da3831",
              border: "none",
              borderRadius: 5,
              paddingInline: 7,
              paddingBlock: 3,
              color: "white",
              fontWeight: "600",
            }}
          >
            Yeni Ekle
          </button>
          {loading && (
            <div
              style={{
                display: "flex",
                height: "50vh",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 30,
              }}
            >
              <Spinner
                animation="border"
                role="status"
                style={{
                  borderBottomColor: "#da3831",
                  borderRightColor: "#da3831",
                  borderLeftColor: "#da3831",
                  borderTopColor: "white",
                }}
              />
            </div>
          )}

          {/* Data Table */}
          {!loading && allDatas.length === 0 && (
            <p className="text-center">Geçmiş gönderi bulunamamıştır.</p>
          )}

          {/* Table */}
          {!loading && allDatas?.length > 0 && (
            <>
              <table style={{ marginBottom: 20 }} className="table">
                <thead>
                  <tr>
                    <th style={{ width: 20, textAlign: "center" }}>No</th>
                    <th style={{ textAlign: "center", width: 200 }}>
                      Müşterinin Adı Soyadı
                    </th>
                    <th style={{ textAlign: "center", width: 100 }}>Telefon</th>
                    <th style={{ textAlign: "center", width: 70 }}>
                      Vestiyer No
                    </th>
                    <th style={{ textAlign: "center", width: 70 }}>
                      Parça Adedi
                    </th>
                    <th style={{ textAlign: "center", width: 150 }}>Tarih</th>
                    <th style={{ textAlign: "center", width: 150 }}>Durum</th>
                    <th style={{ textAlign: "center", width: 50 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {displayedData?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          width: 20,
                          textAlign: "center",
                          fontWeight: "600",
                        }}
                      >
                        {(page - 1) * itemsPerPage + index + 1}
                      </td>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {item.name + " " + item.lastname}
                      </td>
                      <td style={{ textAlign: "center", width: 100 }}>
                        {item.phone}
                      </td>
                      <td style={{ textAlign: "center", width: 70 }}>
                        {item.cloakroomNo}
                      </td>
                      <td style={{ textAlign: "center", width: 70 }}>
                        {item.pieceQuantity}
                      </td>
                      <td style={{ textAlign: "center", width: 150 }}>
                        {moment
                          .unix(item.createdDateTime)
                          .format("DD.MM.YYYY HH:mm")}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          width: 150,
                          color: item.status === "1" ? "green" : "red",
                        }}
                      >
                        {item.status === "1" ? "Teslim Edildi" : "İade Edildi"}
                      </td>
                      <td
                        className="td"
                        style={{
                          textAlign: "center",
                          width: 150,
                          color: "#da3831",
                          fontWeight: "600",
                        }}
                      >
                        <button style={{ marginRight: 10 }}>Teslim Et</button>
                        <button>Düzenle</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Pagination */}
              <Pagination
                className="pagination-container"
                style={{ float: "right", marginBottom: 100 }}
              >
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === page}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </>
          )}
        </div>
        <Modal centered show={addModal} onHide={() => setAddModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Yeni Ekle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="telefon" className="form-label">
                Telefon
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                value={addForm.phone}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="cloakroomNo" className="form-label">
                Vestiyer No
              </label>
              <input
                type="text"
                className="form-control"
                id="cloakroomNo"
                value={addForm.cloakroomNo}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="pieceQuantity" className="form-label">
                Parça Adedi
              </label>
              <input
                type="text"
                className="form-control"
                id="pieceQuantity"
                value={addForm.pieceQuantity}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="note" className="form-label">
                Ek Not
              </label>
              <textarea
                className="form-control"
                id="note"
                rows="3"
                value={addForm.note}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setAddModal(false)}>
              Kapat
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Kaydet
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
}

export default CloakroomPage;
