import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Col, Form, ListGroup } from "react-bootstrap";
import Services from "../../services/Services";
import { MdOutlineCancel } from "react-icons/md";
import "./technicalsupportpage.scss";

const supportOptions = [
  "Teknik Sorun",
  "Operasyonel Sorun",
  "Finansal Sorun",
  "Diğer",
];

function TechnicalSupportPage() {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [token, setToken] = useState("");
  const [selectedSupportOption, setSelectedSupportOption] =
    useState("Teknik Sorun");
  const [description, setDescription] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log("Selected File:", selectedFile);
    setSelectedFiles([...selectedFiles, selectedFile]);
  };

  const handleButtonClick = () => {
    // Trigger a click on the file input
    fileInputRef.current.click();
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const renderFileList = () => {
    return (
      <ListGroup style={{ marginBlock: 30 }}>
        {selectedFiles.map((file, index) => (
          <ListGroup.Item
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {file.name}
            <MdOutlineCancel
              size={22}
              color="red"
              onClick={() => handleRemoveFile(index)}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  return (
    <div className="App">
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            nextTitle={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <div className="support">
            <h5>Teknik Destek</h5>
            <div className="input-container">
              <Form.Label
                style={{ textAlign: "left", display: "flex", fontWeight: 600 }}
                htmlFor="subject"
              >
                Konu
              </Form.Label>
              <Form.Control
                as="select"
                style={{ height: 50 }}
                value={selectedSupportOption}
                onChange={(e) => setSelectedSupportOption(e.target.value)}
              >
                {supportOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>

              <Form.Label
                style={{ textAlign: "left", display: "flex", fontWeight: 600 }}
                htmlFor="description"
              >
                Açıklama
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                style={{ minHeight: "100px" }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Form.Label
                style={{ textAlign: "left", display: "flex", fontWeight: 600 }}
                htmlFor="add-doc"
              >
                Dosya/Görsel Ekle
              </Form.Label>
              <label>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*, .pdf, .doc, .docx" // Set accepted file types
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <Button
                  variant="light"
                  style={{
                    height: "50px",
                    border: "2px dashed #DA3831",
                    borderRadius: "5px",
                    backgroundColor: "white",
                    fontWeight: "600",
                    width: 400,
                  }}
                  onClick={handleButtonClick}
                >
                  Dosya/Görsel Seç
                </Button>
              </label>
              {selectedFiles?.length > 0 && renderFileList()}
              <div className="code-field">
                <Button
                  style={{ backgroundColor: "#DA3831", border: "none" }}
                  className="complete-button"
                  //   onClick={() => null}
                >
                  Gönder
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default TechnicalSupportPage;
